.datatable-header-inner{
    width: 100% !important;
}
@media (min-width: 992px){
    .mt-lg-5 {
        margin-top: 2rem !important;
    }
}
.col-xl-10{
    width: 83.33333333% !important;
}
.col-7{
    width: 58.33333333% !important;
}
.col-md-6{
    width: 50%;
}
.col-6{
    width: 50% !important;
}
.col-5{
    width: 41.66666667% !important;
}
.col-xl-4{
    width: 33.33333333% !important;
}
@media (min-width: 992px){
    .col-lg-4 {
        width: 33.33333333% !important;
    }
}
.col-3{
    width: 25% !important;
}
.col-xl-2{
    width: 16.66666667% !important;
}
.col-2{
    width: 16.66666667% !important;
}
.col-1{
    width: 8.33333333% !important;
}

.btn-primary {
    color: #fff;
    background-color: #6BCABA;
    border-color: #6BCABA;
}

.btn-microsoft {
    color: #fff;
    background-color: #0067b8;
    border-color: #0067b8;
}
.map-container{
    width:auto !important;
}

.ngx-datatable.wrap-header-name.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.text-end{
    justify-content: flex-end;
}

.summary-tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
}
.summary-tabs .nav-tabs .nav-link.active {
    background-color: #fff;
    border-color: #c2c2c2 #c2c2c2 #fff;
}

.summary-tabs .nav-tabs .nav-link:not(.active) h2:hover{
    color: #00B2A9;
}
/* 
.summary-tabs .nav-tabs a.nav-link:not(.active) :hover{
    color: #3dab99 !important;
} */

label{
    display: inline-block;
    margin-bottom: 0.5rem;
}